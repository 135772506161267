import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

interface ITextButtonButton {
    text: string;
    color?: string;
    eventHandler?: () => void;
    className?: string;
}

export const TextButton: React.FC<ITextButtonButton> = (props: ITextButtonButton) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: '100%',
                textAlign: 'center',
                padding: '20px 0px',
            },
            textStyles: {
                color: props.color ? props.color : theme.palette.partnerColor01?.main,
                cursor: 'pointer',
                background: 'none',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '14px',
                },
            },
        }),
    );

    const classes = useStyles();
    return (
        <Box data-qa="text-btn" className={`${classes.root} ${props.className ?? ''}`} onClick={props.eventHandler}>
            <Typography className={classes.textStyles} variant="button" align="center" color="primary">
                {props.text}
            </Typography>
        </Box>
    );
};
