// eslint-disable-next-line budapestian/global-constant-pattern
import { WhitelabelOptions, PartnerCustomColors } from './../types';
import chroma from 'chroma-js';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const config: Record<string, any> = {
    name: 'nexi',
};

export const colors: PartnerCustomColors = {
    partnerColor01: '#132AFF',
    partnerColor02: '#010142',
    partnerColor03: '#010142',
    partnerColor04: chroma('#27E288').luminance(0.5).css(),
    partnerColor05: chroma('#27E288').luminance(0.5).css(),
    partnerColor06: chroma('#FFDF5A').luminance(0.5).css(),
    partnerColor07: chroma('#FF8395').luminance(0.5).css(),
    partnerColor08: '#132AFF',
    partnerColor09: chroma('#27E288').luminance(0.5).css(),
    partnerColor10: chroma('#FF8395').luminance(0.5).css(),
};

const whitelabel: WhitelabelOptions = {
    colors: colors,
    config: config,
};

export default whitelabel;
