import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useFitText } from '@flyyer/use-fit-text';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { PromotionType } from '../../types/Promotion.type';
import WhitelabelContext from '../../styles/whitelabel/WhitelabelContext';

interface ITicketOfferBanner {
    discount: string;
    imagePath?: string;
}

export const TicketOfferBanner: React.FC<ITicketOfferBanner> = (props: ITicketOfferBanner) => {
    const { t } = useTranslation('default');
    const { fontSize, ref } = useFitText({ minFontSize: 100, maxFontSize: 250 }, [props.discount]);
    const location = useLocation();
    const promotionType = (location.state as { promotionType: PromotionType }).promotionType;
    const validityDuration = (location.state as { validityDuration: number }).validityDuration;
    const whitelabelConfig = useContext(WhitelabelContext);
    const assetUrl = whitelabelConfig.getAssetUrl();
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                backgroundImage: `url('${assetUrl}offer-reward-bg.png')`,
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '351px',
                position: 'relative',
                marginTop: '30px',
                marginBottom: '60px',
                [theme.breakpoints.down('sm')]: {
                    height: '260px',
                },
                [theme.breakpoints.down('lg')]: {
                    margin: '0',
                    height: '290px',
                },
                [theme.breakpoints.down('xs')]: {
                    height: '190px',
                },
            },
            contentContainer: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
            },
            discountContainer: {
                width: '100%',
                height: '35%',
                top: '80px',
                position: 'relative',
                [theme.breakpoints.only('sm')]: {
                    top: promotionType === PromotionType.Gift ? '80px' : '100px',
                },
                [theme.breakpoints.down('xs')]: {
                    top: '40px',
                    height: '40%',
                },
            },
            discountAmount: {
                fontFamily: 'Radial-SemiBold',
                fontWeight: 900,
                textAlign: 'center',
                fontSize: fontSize,
                verticalAlign: 'middle',
                lineHeight: '60px',
                color:
                    whitelabelConfig.name === 'nexi'
                        ? theme.palette.partnerColor01?.main
                        : theme.palette.partnerColor04?.main,
                textTransform: 'none',
                maxHeight: '150px',
                width: '60%',
                position: 'relative',
                margin: '0 auto',
                [theme.breakpoints.down('lg')]: {
                    lineHeight: '120%',
                    height: '100%',
                },
            },
            discountValidation: {
                color:
                    whitelabelConfig.name === 'nexi'
                        ? theme.palette.partnerColor01?.main
                        : theme.palette.partnerColor04?.main,
                textAlign: 'center',
                lineHeight: '25px',
                fontSize: '17px',
                maxWidth: '340px',
                width: '100%',
                paddingBottom: '30px',
                [theme.breakpoints.down('lg')]: {
                    paddingBottom: '55px',
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: '13px',
                    lineHeight: '19px',
                    width: '60%',
                    margin: '0 auto',
                    paddingBottom: '30px',
                },
            },
            discountSuffix: {
                display: 'inline',
                marginLeft: '5px',
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box className={classes.root} data-qa="ticket-offer-banner">
            <Box className={classes.contentContainer}>
                <Box className={classes.discountContainer}>
                    <div ref={ref} data-qa="discount-amount" className={classes.discountAmount}>
                        {props.discount}
                        <div className={classes.discountSuffix}>
                            {(promotionType === PromotionType.Percent && '%') ||
                                (promotionType === PromotionType.Cash && '€')}
                        </div>
                    </div>
                </Box>
                <Box>
                    <Typography data-qa="discount-validity" variant="subtitle1" className={classes.discountValidation}>
                        {t(
                            promotionType === PromotionType.Gift
                                ? 'gift-offer-duration-merchant'
                                : 'discount-offer-duration-merchant',
                            { offerValidityInDays: validityDuration },
                        )}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
