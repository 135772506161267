import React from 'react';
import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

interface IRoundedButton {
    text: string;
    eventHandler?: () => void;
    textColor?: string;
    backgroundColor?: string;
    disabled?: boolean;
    type?: 'button' | 'reset' | 'submit';
}

export const RoundedButton: React.FC<IRoundedButton> = (props: IRoundedButton) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            buttonContainer: {
                backgroundColor: props.backgroundColor ? props.backgroundColor : theme.palette.partnerColor01?.main,
                textTransform: 'none',
                width: '100%',
                padding: '20px 0px',
                transition: '0.3s',
                [theme.breakpoints.down('xs')]: {
                    padding: '0',
                },

                '&:hover': {
                    backgroundColor: theme.palette.partnerColor01?.main + '4D',
                },
                '&:hover .MuiTypography-button': {
                    backgroundColor: 'unset',
                },
            },
            text: {
                color: props.textColor ? props.textColor : theme.palette.colorWhite?.main,
                [theme.breakpoints.down('xs')]: {
                    fontSize: '14px',
                    lineHeight: '52px',
                },
            },
        }),
    );

    const classes = useStyles();

    return (
        <Button
            data-qa="rounded-btn"
            disabled={props.disabled}
            className={classes.buttonContainer}
            style={{ borderRadius: 100 }}
            onClick={props.eventHandler}
            type={props.type ? props.type : 'button'}
        >
            <Typography variant="button" className={classes.text}>
                {props.text}
            </Typography>
        </Button>
    );
};
