import React, { useEffect, useState } from 'react';
import { Box, createStyles, LinearProgress, makeStyles, Theme, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

interface IProgressBar {
    text?: string;
    currentStep: number;
    numberOfSteps: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        progressContainer: {
            [theme.breakpoints.down('xs')]: {
                alignItems: 'end',
            },
            [theme.breakpoints.up('md')]: {
                marginTop: '20px',
            },
        },
        textContainer: {
            minWidth: '120px',
            [theme.breakpoints.down('xs')]: {
                minWidth: '85px',
            },
        },
        progressText: {
            background: 'none',
            [theme.breakpoints.down('xs')]: {
                textTransform: 'uppercase',
            },
        },
        progressLineContainer: {
            [theme.breakpoints.down('xs')]: {
                position: 'relative',
                bottom: '0px',
                alignSelf: 'center',
                marginTop: '8px',
            },
        },
        mainColor: {
            color: theme.palette.partnerColor01?.main,
            fontSize: '18px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '12px',
                lineHeight: '14px',
            },
        },
        secondaryColor: {
            color: theme.palette.partnerColor02?.main,
            fontSize: '18px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '12px',
                lineHeight: '14px',
            },
        },
        mainBar: {
            backgroundColor: theme.palette.partnerColor01?.main,
        },
        secondaryBar: {
            backgroundColor: theme.palette.partnerColor01?.main + '33',
        },
    }),
);

export const ProgressBar: React.FC<IProgressBar> = (props: IProgressBar) => {
    const classes = useStyles();

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const calculatedPercentage = (props.currentStep / props.numberOfSteps) * 100;
        setProgress(calculatedPercentage);
    }, []);

    return (
        <Box className={classes.progressContainer} display="flex" alignItems="center">
            <Box className={classes.textContainer}>
                <Typography className={classes.progressText} variant="button">
                    <Trans
                        i18nKey={'default:select-offer-step'}
                        values={{ stepNumber: props.currentStep, totalSteps: props.numberOfSteps }}
                        components={{
                            ColorBlue: <span className={classes.mainColor} />,
                            ColorBlack: <span className={classes.secondaryColor} />,
                        }}
                    />
                </Typography>
            </Box>
            <Box className={classes.progressLineContainer} width="100%">
                <LinearProgress
                    variant="determinate"
                    value={progress}
                    classes={{ barColorPrimary: classes.mainBar, colorPrimary: classes.secondaryBar }}
                />
            </Box>
        </Box>
    );
};
