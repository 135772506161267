// eslint-disable-next-line budapestian/global-constant-pattern
import { WhitelabelOptions, PartnerCustomColors } from './../types';
import chroma from 'chroma-js';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const config: Record<string, any> = {
    name: 'mastercard',
};

export const colors: PartnerCustomColors = {
    partnerColor01: '#ED8B00',
    partnerColor02: '#000000',
    partnerColor03: '#000000',
    partnerColor04: chroma('#40B086').luminance(0.5).css(),
    partnerColor05: chroma('#ED8B00').luminance(0.5).css(),
    partnerColor06: chroma('#ED8B00').luminance(0.5).css(),
    partnerColor07: chroma('#ED8B00').luminance(0.5).css(),
    partnerColor08: '#FFFFFF',
    partnerColor09: chroma('#40B086').luminance(0.5).css(),
    partnerColor10: '#ED8B00',
};

const whitelabel: WhitelabelOptions = {
    colors: colors,
    config: config,
};

export default whitelabel;
