import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

interface ISectionTitle {
    text: string;
    center?: boolean;
    fontSize?: string;
    className?: string;
}

export const SectionTitle: React.FC<ISectionTitle> = (props: ISectionTitle) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                textAlign: props.center ? 'center' : 'left',
                fontSize: props.fontSize ?? theme.typography.h1.fontSize,
                color: theme.palette.partnerColor02?.main,
                [theme.breakpoints.up('md')]: {
                    fontSize: '44px',
                },
            },
        }),
    );

    const classes = useStyles();

    return (
        <Typography
            data-qa="section-title"
            variant="h1"
            color="textPrimary"
            className={`${classes.root} ${props.className ?? ''}`}
        >
            {props.text}
        </Typography>
    );
};
