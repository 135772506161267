import { OfferRecommendation } from '../../../shared/types/OfferTypeScreen.type';

export const offersData: OfferRecommendation[] = [
    {
        rank: 1, // (int) // To be used as hint to nudge merchant towards one offer vs presenting all recos as as good. if 2 recommendations have the same rank, we consider that no one is better than the other.
        offerStages: [
            {
                rewardType: 'VOUCHER', // (String) cashback/voucher
                rewardUnit: 'PERCENT', // (String) cash/percent/gift
                recommendedRewardValue: '10.0', // (BigDecimal) recommended reward value
                minRewardValue: 1.0, // (BigDecimal) warn merchant if choosing reward below - nullable
                maxRewardValue: 95.0, // (BigDecimal) warn merchant if choosing reward above - nullable
                voucherValidityPeriod: 60, // (Integer) recommended voucher validity in days
                pointCost: null, // (BigDecimal) future
                minTransactionAmountForVisitAccrual: null, // (BigDecimal) nullable min amount to count a transaction as a visit
                triggerType: null, // (String) visits (TRANSACTIONS_COUNT), amount (TRANSACTIONS_SUM), or points
                triggerValue: null, // (BigDecimal) accrued value that triggers the reward
                generosity: null, // (BigDecimal) generosity that the recommended reward value and trigger would represent - nullable
                minGenerosity: null, // (BigDecimal) generosity that the min reward value and trigger would represent - nullable
                maxGenerosity: null, // (BigDecimal)
            }, // OfferStageRecommendation
        ], // here it's an Array because for loyalty offer we'll have several steps, not only one!
    },
    {
        rank: 2,
        offerStages: [
            {
                rewardType: 'VOUCHER',
                rewardUnit: 'CASH',
                recommendedRewardValue: '5.0',
                minRewardValue: 1.0,
                maxRewardValue: 100,
                voucherValidityPeriod: 60,
                pointCost: null,
                minTransactionAmountForVisitAccrual: null,
                triggerType: null,
                triggerValue: null,
                generosity: null,
                minGenerosity: null,
                maxGenerosity: null,
            },
        ],
    },
    {
        rank: 3,
        offerStages: [
            {
                rewardType: 'VOUCHER',
                rewardUnit: 'GIFT',
                recommendedRewardValue: '',
                minRewardValue: null,
                maxRewardValue: null,
                voucherValidityPeriod: 60,
                pointCost: null,
                minTransactionAmountForVisitAccrual: null,
                triggerType: null,
                triggerValue: null,
                generosity: null,
                minGenerosity: null,
                maxGenerosity: null,
            },
        ],
    },
];
