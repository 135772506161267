import React from 'react';
import { createStyles, FormHelperText, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { PromotionType } from '../../types/Promotion.type';
interface IPromotionTextInput {
    type?: string;
    inputMode?: 'numeric' | 'text';
    className?: string;
    pattern?: string;
    value: string;
    min?: number;
    max?: number;
    maxLength?: number;
    step?: string;
    error: string | null;
    fullWidth?: boolean;
    promotionType: PromotionType;
    setDiscountValue: (value: string) => void;
    handleMaxLength?: (value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        promotionInput: {
            color: theme.palette.partnerColor01?.main,
        },
        promotionValidation: {
            position: 'absolute',
            bottom: '10px',
            color: theme.palette.colorError?.main + 'E6',
            '& span': {
                color: theme.palette.colorError?.main,
            },
        },
    }),
);

export const PromotionTextInput: React.FC<IPromotionTextInput> = (props: IPromotionTextInput) => {
    const classes = useStyles();

    const lGetInputWidth = {
        [PromotionType.Cash]: '150px',
        [PromotionType.Percent]: '100px',
        [PromotionType.Gift]: '100%',
    };

    return (
        <>
            <TextField
                type={props.type}
                fullWidth={props.fullWidth}
                classes={{
                    root: props.className,
                }}
                error
                value={props.value}
                onChange={(e) => {
                    props.setDiscountValue(e.target.value);
                    props.handleMaxLength ? props.handleMaxLength(e) : null;
                }}
                inputProps={{
                    name: 'promotion-input',
                    'data-qa': 'promotion-input',
                    type: props.type,
                    min: props.min || 1,
                    max: props.max || 1000,
                    maxLength: props.maxLength || 40,
                    inputMode: props.inputMode,
                    pattern: props.pattern || null,
                    step: props.step,
                    className: classes.promotionInput,
                    style: { maxWidth: lGetInputWidth[props.promotionType] },
                }}
                InputProps={{
                    disableUnderline: true,
                }}
            />
            <FormHelperText className={`promotion-validation ${classes.promotionValidation}`}>
                {props.error && (
                    <Typography className="error-message" component="span" variant="h4" data-qa="validation-error">
                        {props.error}
                    </Typography>
                )}
            </FormHelperText>
        </>
    );
};
