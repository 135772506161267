import React from 'react';
import { Box, makeStyles, createStyles, Theme, useTheme } from '@material-ui/core';

interface IGoBackButton {
    eventHandler: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'absolute',
            top: '2%',
            left: '5%',
            cursor: 'pointer',
        },
        icon: {
            fill: theme.palette.partnerColor01?.main,
        },
        backIcon: {
            height: '24px',
            [theme.breakpoints.down('xs')]: {
                height: '18px',
            },
        },
    }),
);

export const GoBackButton: React.FC<IGoBackButton> = (props: IGoBackButton) => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Box data-qa="go-back-btn" className={classes.root} onClick={props.eventHandler}>
            <svg className={classes.backIcon} viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.2904 1.34186C11.1273 0.505023 12.484 0.505025 13.3209 1.34186C14.1577 2.1787 14.1577 3.53548 13.3209 4.37232L7.86656 9.82663L28.9694 9.82663C30.1529 9.82663 31.1123 10.786 31.1123 11.9695C31.1123 13.153 30.1529 14.1123 28.9694 14.1123L7.9087 14.1123L13.321 19.5246C14.1578 20.3615 14.1578 21.7182 13.321 22.5551C12.4841 23.3919 11.1273 23.3919 10.2905 22.5551L1.211 13.4756L1.19904 13.4637C0.362202 12.6269 0.362202 11.2701 1.19904 10.4332L10.2904 1.34186Z"
                    fill={theme.palette.partnerColor08?.main}
                    className={classes.icon}
                />
            </svg>
        </Box>
    );
};
