import {} from '@material-ui/core/styles/createPalette';
import nexi from './partners/nexi';
import mastercard from './partners/mastercard';
import cefr from './partners/cefr';
import defaultConfig, { createPartnerTheme } from './defaults';
import { WhitelabelOptions, Whitelabel, WhitelabelType } from './types';
import assign from 'object-assign-deep';
// Add a new "shadow" variant for the Button component
declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        shadow: true;
    }
}

// Extend MUI palette
declare module '@material-ui/core/styles/createPalette' {
    interface Palette {
        partnerColor01: PaletteColor;
        partnerColor02: PaletteColor;
        partnerColor03: PaletteColor;
        partnerColor04: PaletteColor;
        partnerColor05: PaletteColor;
        partnerColor06: PaletteColor;
        partnerColor07: PaletteColor;
        partnerColor08: PaletteColor;
        partnerColor09: PaletteColor;
        partnerColor10: PaletteColor;
        colorWhite: PaletteColor;
        colorBlack: PaletteColor;
        colorSystem: PaletteColor;
        colorGrey1: PaletteColor;
        colorGrey2: PaletteColor;
        colorSuccess: PaletteColor;
        colorWarning: PaletteColor;
        colorError: PaletteColor;
        colorInfo: PaletteColor;
    }

    interface PaletteOptions {
        partnerColor01: PaletteColorOptions;
        partnerColor02: PaletteColorOptions;
        partnerColor03: PaletteColorOptions;
        partnerColor04: PaletteColorOptions;
        partnerColor05: PaletteColorOptions;
        partnerColor06: PaletteColorOptions;
        partnerColor07: PaletteColorOptions;
        partnerColor08: PaletteColorOptions;
        partnerColor09: PaletteColorOptions;
        partnerColor10: PaletteColorOptions;
        colorWhite: PaletteColorOptions;
        colorBlack: PaletteColorOptions;
        colorSystem: PaletteColorOptions;
        colorGrey1: PaletteColorOptions;
        colorGrey2: PaletteColorOptions;
        colorSuccess: PaletteColorOptions;
        colorWarning: PaletteColorOptions;
        colorError: PaletteColorOptions;
        colorInfo: PaletteColorOptions;
    }
}

const whitelabels = createWhitelabels(
    {
        nexi,
        mastercard,
        cefr,
    },
    defaultConfig,
);

export const availableWhitelabels = Object.keys(whitelabels);

export const defaultWhitelabel: WhitelabelType = 'nexi';

export function getWhitelabel(whitelabelName: string | null): WhitelabelType {
    if (whitelabelName) {
        return availableWhitelabels.includes(whitelabelName) ? (whitelabelName as WhitelabelType) : defaultWhitelabel;
    }

    return defaultWhitelabel;
}

export function getInitWhitelabel(): WhitelabelType {
    const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
    const lWhitelabel: string | null = queryParams.get('partnerName');
    return getWhitelabel(lWhitelabel);
}

export function createWhitelabels<R extends { [k: string]: WhitelabelOptions }>(
    whitelabelParam: R,
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    defaultConfig: Record<string, any>,
): { [Type in keyof R & string]: Whitelabel } {
    const resWhitelabels: { [Type in keyof R & string]: Whitelabel } = Object.entries(whitelabelParam).reduce(
        (acc, [key, { colors, config }]) => ({
            ...acc,
            [key]: {
                theme: createPartnerTheme(colors),
                config: assign({}, defaultConfig, config),
            },
        }),
        /* eslint-disable  @typescript-eslint/no-explicit-any */
        {} as any as { [Type in keyof R & string]: Whitelabel },
    );

    return resWhitelabels;
}

export default whitelabels;
