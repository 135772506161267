import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, useTheme } from '@material-ui/core';
import { GoBackButton, OfferTypeButton, ProgressBar, SectionSubtitle, SectionTitle } from '../../shared/components';
import { POSInfoContext } from '../../shared/contexts/POSInfoContext';
import { useTracking } from '../../shared/hooks/useTracking';
import { trackingService, offersService, restAPI } from '../../shared/services';
import { WelcomeOfferType } from '../../shared/types/OfferTypeScreen.type';
import './OfferTypeScreen.scss';

type Progress = {
    currentStep: number;
    numberOfSteps: number;
};

interface IOfferTypeScreen {
    progress: Progress;
    offers?: WelcomeOfferType[];
}

export const OfferTypeScreen: React.FC<IOfferTypeScreen> = (props: IOfferTypeScreen) => {
    const { t } = useTranslation('default');
    const history = useHistory();
    const [lOffers, setlOffers] = useState([] as WelcomeOfferType[]);
    const posInfo = useContext(POSInfoContext);
    const theme = useTheme();

    useEffect(() => {
        const { shopUniqueId, code, rank } = posInfo;
        offersService.getOffers(restAPI.getShopInfo({ shopUniqueId, code, rank })).then((data) => {
            setlOffers(data);
        });
    }, []);
    const lMixpanelProps = useTracking('ChooseType', posInfo);

    const handleOfferClick = (offerType: string) => {
        const hasSelectedRecomendedType = (offerType: string) => {
            return lOffers.find((offer) => offer.type === offerType && offer.recommended) ? 'true' : 'false';
        };
        trackingService.track('WelcomeOfferSetup.ChooseTypeBtn', {
            ...lMixpanelProps,
            WoType: offerType,
            HasSelectedReco: hasSelectedRecomendedType,
        });

        history.push({
            pathname: '/promotion',
            search: `?promotionType=${offerType}`,
            state: {
                promotionType: offerType,
                offer: lOffers.find((offer) => offer.type === offerType),
            },
        });
    };

    const handleGoBack = () => {
        history.goBack();
    };

    return (
        <Box className="offer-type-container" bgcolor={theme.palette.colorGrey1?.main}>
            <GoBackButton eventHandler={handleGoBack} />
            <Box className="offer-type-inner-container" bgcolor={theme.palette.colorWhite?.main}>
                <Box className="offer-type-wrapper">
                    <SectionTitle text={t('configure-welcome-offer-title')} className="section-title" />
                    <ProgressBar
                        currentStep={props.progress.currentStep}
                        numberOfSteps={props.progress.numberOfSteps}
                    />
                    <SectionSubtitle
                        text={t('select-customer-reward', {
                            offerValidityInDays: (lOffers.length && lOffers[0].duration) || 30,
                        })}
                        className="section-subtitle"
                    />

                    {lOffers.length > 0 &&
                        lOffers.map((offer, index) => (
                            <OfferTypeButton
                                key={index}
                                type={offer.type}
                                title={offer.title}
                                description={offer.description}
                                recommended={offer['recommended'] || false}
                                isLast={index === lOffers.length - 1}
                                backgroundColor={offer.backgroundColor || ''}
                                onOfferClick={handleOfferClick}
                            />
                        ))}
                </Box>
            </Box>
        </Box>
    );
};
