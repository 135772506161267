import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

interface ISectionSubtitle {
    text: string;
    color?: string;
    className?: string;
}

export const SectionSubtitle: React.FC<ISectionSubtitle> = (props: ISectionSubtitle) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            sectionDesc: {
                marginBottom: '100px',
                marginTop: '15px',
                color: props.color ? props.color : theme.palette.partnerColor03?.main,
                [theme.breakpoints.down('lg')]: {
                    marginBottom: '32px',
                },
                [theme.breakpoints.down('xs')]: {
                    marginBottom: '22px',
                },
                [theme.breakpoints.up('md')]: {
                    marginTop: '20px',
                    marginBottom: '52px',
                },
            },
        }),
    );
    const classes = useStyles();

    return (
        <Typography
            data-qa="section-desc"
            variant="h3"
            color="textSecondary"
            className={`${classes.sectionDesc} ${props.className ?? ''}`}
        >
            {props.text}
        </Typography>
    );
};
