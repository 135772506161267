import { Redirect, Route, useHistory } from 'react-router-dom';
import { ActivationScreen, OfferTypeScreen, TicketFinalScreen } from './screens';
import { PromotionScreen } from './screens/PromotionScreen/PromotionScreen';
import { TutorialScreen } from './screens/TutorialScreen/TutorialScreen';
import React, { useContext } from 'react';
import { restAPI } from './shared/services';
import { POSInfoContext } from './shared/contexts/POSInfoContext';
import { GetShopResponse } from './shared/services/RestAPI.service';

export const Routes: React.FunctionComponent = () => {
    const history = useHistory();
    const posInfo = useContext(POSInfoContext);
    const { shopUniqueId, code, rank } = posInfo;
    const [redirectState, setRedirectState] = React.useState('');
    React.useEffect(() => {
        const checkState = () => {
            restAPI
                .getShopInfo({ shopUniqueId, code, rank })
                .then((res) => {
                    res.json().then((data: GetShopResponse) => {
                        if (data.welcomeOfferActivated) setRedirectState('tutorial');
                        else setRedirectState('activation');
                    });
                })
                .catch(() => {
                    setRedirectState('activation');
                });
        };

        checkState();
    }, []);

    if (!redirectState) return <div></div>;

    return (
        <>
            <Route exact path="/">
                <Redirect to={redirectState} />
            </Route>
            <Route path="/activation">
                <ActivationScreen />
            </Route>
            <Route path="/offer-type">
                <OfferTypeScreen progress={{ currentStep: 1, numberOfSteps: 2 }} />
            </Route>
            <Route path="/promotion">
                <PromotionScreen progress={{ currentStep: 2, numberOfSteps: 2 }} />
            </Route>
            <Route path="/ticket">
                <TicketFinalScreen />
            </Route>
            <Route path="/tutorial">
                <TutorialScreen />
            </Route>
        </>
    );
};
