import React, { useEffect, useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import { createI18n } from './assets/i18n/i18n';
import { Routes } from './Routes';
import { Toaster } from './shared/components/Toaster/Toaster';
import { POSInfoContext } from './shared/contexts/POSInfoContext';
import { posInformation } from './shared/services/PosInformation.service';
import { trackingService } from './shared/services/Tracking.service';
import { ConfirmationScreen } from './screens/ConfirmationScreen/ConfirmationScreen';
import whitelabels, { getWhitelabel } from './shared/styles/whitelabel/whitelabel';
import { WhitelabelType } from './shared/styles/whitelabel/types';
import WhitelabelContext from './shared/styles/whitelabel/WhitelabelContext';
import './App.scss';

const POSWelcome: React.FunctionComponent = () => {
    const search = useLocation().search;
    const [posInfo] = useState(() => posInformation(search));
    const tracking = trackingService;
    const currentWhitelabel: WhitelabelType = getWhitelabel(posInfo.partnerName as string);

    createI18n(posInfo.lang);
    tracking.identify(posInfo.serial);

    const ScrollToTop = () => {
        const { pathname } = useLocation();
        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);
        return null;
    };

    return (
        <ThemeProvider theme={whitelabels[currentWhitelabel].theme}>
            <WhitelabelContext.Provider value={whitelabels[currentWhitelabel].config}>
                <ScrollToTop />
                <POSInfoContext.Provider value={posInfo}>
                    <Switch>
                        <Routes />
                    </Switch>
                    <Toaster />
                </POSInfoContext.Provider>
            </WhitelabelContext.Provider>
        </ThemeProvider>
    );
};

const App: React.FunctionComponent = () => {
    const { pathname, search } = useLocation();
    const lang = new URLSearchParams(search).get('lang');
    if (pathname === '/confirmation') {
        createI18n(lang || 'en_US', 'en_US');
    }

    return pathname === '/confirmation' ? <ConfirmationScreen /> : <POSWelcome />;
};

export default App;
