// eslint-disable-next-line budapestian/global-variable-pattern
import React, { useContext } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SectionTitle, TextButton, TicketOfferBanner, TicketOfferMessage } from '../../shared/components';
import { POSInfoContext } from '../../shared/contexts/POSInfoContext';
import { useTracking } from '../../shared/hooks/useTracking';
import { trackingService } from '../../shared/services/Tracking.service';
import { SelfOptin } from '../../shared/components/SelfOptin/SelfOptin';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Android: any;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ticketFinalContainer: {
            maxWidth: '90%',
            width: '100%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
        },
        ticketCheckIcon: {
            maxWidth: '47px',
            margin: '0 auto',
            marginTop: '90px',
            marginBottom: '25px',
            [theme.breakpoints.down('lg')]: {
                margin: '27px auto 12px auto',
            },
            [theme.breakpoints.down('xs')]: {
                maxWidth: '25px',
                margin: '20px auto 12px auto',
            },
        },
        notNowButton: {
            width: 'calc(100% - 80px) !important',
            position: 'fixed',
            left: '40px',
            bottom: 0,
            margin: '0 auto',
            [theme.breakpoints.down('sm')]: {
                padding: '20px 0 !important',
            },
            [theme.breakpoints.down('lg')]: {
                margin: '0',
                position: 'relative',
                bottom: '20px',
            },
            [theme.breakpoints.only('sm')]: {
                padding: '0 !important',
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '14px !important',
                bottom: 0,
                padding: '10px 0 !important',
            },
        },
        finalTitle: {
            lineHeight: '36px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '22px !important',
                lineHeight: '24px',
            },
        },
    }),
);

export const TicketFinalScreen: React.FC = () => {
    const { t } = useTranslation('default');
    const location = useLocation();
    const discount = (location.state as { discount: string }).discount;
    const classes = useStyles();
    const posInfo = useContext(POSInfoContext);

    const lMixpanelProps = useTracking('Confirmation', posInfo);

    const closeAndStart = () => {
        trackingService.track('WelcomeOfferSetup.Confirmation.CloseBtn', lMixpanelProps);

        if (typeof Android !== 'undefined') {
            Android.onModuleFinished();
        } else {
            // eslint-disable-next-line no-console
            console.log('closeAndStart called');
        }
    };

    return (
        <Box className={classes.ticketFinalContainer}>
            <img
                data-qa="ticket-check-icon"
                className={classes.ticketCheckIcon}
                src="/assets/icons/circle-checked.png"
            />
            <SectionTitle text={t('offer-activated-title')} center className={classes.finalTitle} />
            <TicketOfferBanner discount={discount} />
            <SelfOptin fromScreen={'ticket'}>
                <TicketOfferMessage
                    title={t('start-enrolling-customers')}
                    firstParagraph={t('optin-explanation')}
                    secondParagraph={t('up-to-you')}
                />
                <TextButton eventHandler={closeAndStart} className={classes.notNowButton} text={t('close-and-start')} />
            </SelfOptin>
        </Box>
    );
};
