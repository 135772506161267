import { offersData } from './Offers.data';
import { OfferRecommendation, WelcomeOfferType } from '../../types/OfferTypeScreen.type';
import { NodeEnvironment } from '../../types/EnvironmentVariables';
import { PromotionType } from '../../types/Promotion.type';

interface IOffersService {
    getOffers: (getShopInfo: Promise<Response>) => Promise<WelcomeOfferType[]>;
}

class OffersService implements IOffersService {
    // Fetch the default recommendation json from remote bucket
    private fetchDefaultRecommendation = () => {
        return fetch('https://woop-static.s3.eu-west-3.amazonaws.com/offers.json').then((fetchedResponse) => {
            // TODO: optimize the await with a timeout
            if (!fetchedResponse.ok) {
                return this.transformOffers(offersData);
            } else {
                return fetchedResponse.json().then((jsonData) => {
                    return this.dedupeOffers(this.mergeOffers(jsonData, offersData));
                });
            }
        });
    };

    private sortOffers = (offers: WelcomeOfferType[]): WelcomeOfferType[] => {
        const compare = (firstOffer: WelcomeOfferType, secondOffer: WelcomeOfferType) => {
            const lRanks = {
                [PromotionType.Percent]: 1,
                [PromotionType.Cash]: 2,
                [PromotionType.Gift]: 3,
            };
            const firstRank = firstOffer.recommended ? 0 : lRanks[firstOffer.type];
            const secondRank = secondOffer.recommended ? 0 : lRanks[secondOffer.type];
            return firstRank - secondRank;
        };

        return offers.sort(compare);
    };

    private transformOffers = (offers: OfferRecommendation[], recommended?: boolean): WelcomeOfferType[] => {
        const lHighestRank: number = offers.map((offer) => offer.rank).sort()[0];
        const lGetOfferTitleKey = {
            [PromotionType.Percent]: 'a-percent-discount',
            [PromotionType.Cash]: 'a-euro-discount',
            [PromotionType.Gift]: 'a-gift',
        };

        return offers.map((offer: OfferRecommendation, index) => ({
            id: index,
            rank: offer.rank,
            type: offer.offerStages[0].rewardUnit.toLocaleLowerCase(),
            title: lGetOfferTitleKey[offer.offerStages[0].rewardUnit.toLocaleLowerCase()],
            description: `define-next-visit-${
                offer.offerStages[0].rewardUnit.toLocaleLowerCase() === 'gift' ? 'gift' : 'discount'
            }`,
            duration: offer.offerStages[0].voucherValidityPeriod,
            recommended: recommended && offer.rank === lHighestRank ? true : false,
            recommendedDiscount: offer.offerStages[0].recommendedRewardValue?.toString() || '',
            minRewardValue: offer.offerStages[0].minRewardValue || 0,
            maxRewardValue: offer.offerStages[0].maxRewardValue || 0,
        }));
    };

    private mergeOffers = (offers: OfferRecommendation[], defaultOffers: OfferRecommendation[]): WelcomeOfferType[] => {
        const fetchedOffers = this.sortOffers(this.transformOffers(offers, true));
        const defaultTransformedOffers = this.transformOffers(defaultOffers);

        const overrideNullValues = fetchedOffers.map((fetchedOffer) => {
            if (fetchedOffer.minRewardValue === 0) {
                const defaultMinRewardValue = defaultTransformedOffers.find(
                    (defaultOffer) => defaultOffer.type === fetchedOffer.type,
                )?.minRewardValue;
                if (defaultMinRewardValue) {
                    fetchedOffer.minRewardValue = defaultMinRewardValue;
                }
            }

            if (fetchedOffer.maxRewardValue === 0) {
                const defaultMaxRewardValue = defaultTransformedOffers.find(
                    (defaultOffer) => defaultOffer.type === fetchedOffer.type,
                )?.maxRewardValue;
                if (defaultMaxRewardValue) {
                    fetchedOffer.maxRewardValue = defaultMaxRewardValue;
                }
            }
            return fetchedOffer;
        });
        return [...overrideNullValues, ...defaultTransformedOffers];
    };

    private dedupeOffers = (mergedOffers: WelcomeOfferType[]): WelcomeOfferType[] => {
        const uniqueOfferTypes = new Set();
        const dedupeOffers = mergedOffers.filter((offer) => {
            const duplicate = uniqueOfferTypes.has(offer.type);
            uniqueOfferTypes.add(offer.type);
            return !duplicate;
        });

        return dedupeOffers;
    };

    getOffers = (getShopInfo: Promise<Response>): Promise<WelcomeOfferType[]> => {
        // Fetch the shop category and then the category recommendation, otherwise try the default one
        const fetchRecommendationFromCategoryOrFallback = (getShopInfo: Promise<Response>) => {
            return getShopInfo.then((shopInfoResponse) => {
                if (!shopInfoResponse.ok) {
                    return this.fetchDefaultRecommendation();
                } else {
                    return shopInfoResponse.json().then((shopInfo) => {
                        return !shopInfo.parentBusinessCategoryId
                            ? this.fetchDefaultRecommendation()
                            : fetch(
                                  `https://woop-static.s3.eu-west-3.amazonaws.com/${shopInfo.shopCountry.toLowerCase()}/${
                                      shopInfo.parentBusinessCategoryId
                                  }.json`,
                              ).then((fetchedResponse) => {
                                  // TODO: optimize the await with a timeout
                                  if (!fetchedResponse.ok) {
                                      return this.fetchDefaultRecommendation();
                                  } else {
                                      return fetchedResponse.json().then((jsonData) => {
                                          return this.dedupeOffers(this.mergeOffers(jsonData, offersData));
                                      });
                                  }
                              });
                    });
                }
            });
        };

        return fetchRecommendationFromCategoryOrFallback(getShopInfo).catch(() => {
            return this.fetchDefaultRecommendation().catch(() => {
                return this.transformOffers(offersData);
            });
        });
    };
}

class MockOffersService implements IOffersService {
    getOffers = (_params: Promise<Response>) => {
        return Promise.resolve([] as WelcomeOfferType[]);
    };
}

export const offersService = ((): IOffersService => {
    const nodeEnv: NodeEnvironment = process.env.NODE_ENV;
    if (nodeEnv === 'production') {
        return new OffersService();
    } else if (nodeEnv === 'development') {
        return new OffersService();
    } else {
        return new MockOffersService();
    }
})();
