import React, { useContext } from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CashIcon } from './../../../assets/common/icons/cash-icon.svg';
import { ReactComponent as PercentIcon } from './../../../assets/common/icons/percent-icon.svg';
import { ReactComponent as GiftIcon } from './../../../assets/common/icons/gift-icon.svg';
import { PromotionType } from '../../types/Promotion.type';
import { POSInfoContext } from '../../contexts/POSInfoContext';

interface IOfferTypeButton {
    type: string;
    title: string;
    description?: string;
    imagePath?: string;
    isLast?: boolean;
    backgroundColor?: string;
    recommended?: boolean;
    onOfferClick: (type: string) => void;
}

export const OfferTypeButton: React.FC<IOfferTypeButton> = (props: IOfferTypeButton) => {
    const { t } = useTranslation('default');
    const posInfo = useContext(POSInfoContext);

    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                margin: '0 auto',
                display: 'flex',
                overflow: props.recommended ? 'visible' : 'hidden',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: props.backgroundColor ? props.backgroundColor : theme.palette.colorWhite?.main,
                borderRadius: '12px',
                marginBottom: props.isLast ? '0' : '24px',
                padding: '0px 29px',
                position: 'relative',
                height: '141px',
                boxShadow: `2px 2px 10px ${theme.palette.colorGrey1?.main}`,
                border: `1px solid ${theme.palette.colorGrey1?.main}`,
                [theme.breakpoints.down('xs')]: {
                    height: '84px',
                    padding: '0 18px',
                },
            },
            offerIconContainer: {
                maxWidth: '90px',
                width: '100%',
                maxHeight: '90px',
                height: '100%',
                borderRadius: '100%',
                backgroundColor: `${
                    (props.type === PromotionType.Percent && theme.palette.partnerColor05?.main) ||
                    (props.type === PromotionType.Cash && theme.palette.partnerColor06?.main) ||
                    (props.type === PromotionType.Gift && theme.palette.partnerColor07?.main)
                }`,
                [theme.breakpoints.down('xs')]: {
                    maxWidth: '40px',
                    maxHeight: '40px',
                },
            },
            offerIcon: {
                height: '100%',
                margin: '0 auto',
                display: 'block',
                width: '60px',
                fill: `${
                    (props.type === PromotionType.Percent && theme.palette.partnerColor08?.main) ||
                    (props.type === PromotionType.Cash && theme.palette.partnerColor08?.main) ||
                    (props.type === PromotionType.Gift && theme.palette.partnerColor08?.main)
                }`,
                [theme.breakpoints.down('xs')]: {
                    width: '25px',
                },
            },
            offerTitle: {
                color: theme.palette.partnerColor01?.main,
                [theme.breakpoints.down('xs')]: {
                    fontSize: '16px',
                    lineHeight: '20px',
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '32px',
                },
            },
            offerDescriptionContainer: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginLeft: '45px',
                width: '100%',
                padding: '50px 0px',
                [theme.breakpoints.down('xs')]: {
                    marginLeft: '12px',
                },
            },
            offerDescription: {
                fontWeight: 500,
                color: theme.palette.partnerColor02?.main,
                background: 'none',

                [theme.breakpoints.down('sm')]: {
                    fontSize: '20px',
                    lineHeight: '22px',
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: '14px',
                    lineHeight: '17px',
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: '24px',
                },
            },
            offerRecommendation: {
                height: '30px',
                backgroundColor: theme.palette.partnerColor04?.main,
                position: 'absolute',
                top: -20,
                left: 0,
                borderRadius: '20px 0px 20px 0px',
                padding: '0 2%',
                [theme.breakpoints.down('xs')]: {
                    height: '18px',
                    top: -9,
                },
            },
            offerRecommendationText: {
                fontFamily: 'Radial-SemiBold',
                fontWeight: 600,
                color:
                    posInfo.partnerName === 'nexi'
                        ? theme.palette.partnerColor01?.main
                        : theme.palette.colorWhite?.main,
                lineHeight: '30px',
                textAlign: 'center',
                fontSize: '15px',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '10px',
                    lineHeight: '18px',
                },
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box className={classes.root} data-qa="offer-button" onClick={() => props.onOfferClick(props.type)}>
            {props.recommended && (
                <Box className={classes.offerRecommendation}>
                    <Typography variant="h4" className={classes.offerRecommendationText}>
                        {t('our-recommendation')}
                    </Typography>
                </Box>
            )}
            <Box className={classes.offerIconContainer}>
                {(props.type === PromotionType.Cash && <CashIcon className={classes.offerIcon} />) ||
                    (props.type === PromotionType.Percent && <PercentIcon className={classes.offerIcon} />) ||
                    (props.type === PromotionType.Gift && <GiftIcon className={classes.offerIcon} />)}
            </Box>
            <Box className={classes.offerDescriptionContainer}>
                <Typography data-qa="offer-title" variant="h2" className={classes.offerTitle}>
                    {t(props.title)}
                </Typography>
                {props.description && (
                    <Typography
                        data-qa="offer-desc"
                        variant="button"
                        color="secondary"
                        className={classes.offerDescription}
                    >
                        {t(props.description)}
                    </Typography>
                )}
            </Box>
        </Box>
    );
};
