// eslint-disable-next-line budapestian/global-variable-pattern
import React, { useContext, useEffect } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CheckCircle } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../shared/components/Toaster/toaster.scss';
import WhitelabelContext from '../../shared/styles/whitelabel/WhitelabelContext';

export const ConfirmationScreen: React.FC = () => {
    const { t } = useTranslation('default');
    const search = useLocation().search;
    const whitelabelConfig = useContext(WhitelabelContext);
    const assetUrl = whitelabelConfig.getAssetUrl();
    const status = new URLSearchParams(search).get('status');
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            ticketFinalContainer: {
                maxWidth: '720px',
                height: '100vh',
                width: 'calc(100% - 40px)',
                margin: '0 auto',
                padding: '0 20px',
                display: 'flex',
                flexDirection: 'column',
            },
            ticketCheckIcon: {
                maxWidth: '47px',
                margin: '0 auto',
                fontSize: '46px',
                display: 'block',
                color: '#0ACF83',
            },
            root: {
                backgroundImage: `url('${assetUrl}confirm-success-bg.png')`,
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                height: '351px',
                position: 'relative',
                marginTop: '30px',
                marginBottom: '60px',
                [theme.breakpoints.down('sm')]: {
                    height: '310px',
                    backgroundImage: `url('${assetUrl}confirm-success-bg-mobile.png')`,
                },
            },
            contentContainer: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '100%',
            },
            discountContainer: {
                width: '100%',
                height: '35%',
                top: '80px',
                position: 'relative',
                [theme.breakpoints.only('xs')]: {
                    top: '70px',
                },
            },
            discountAmount: {
                fontFamily: 'Radial-SemiBold',
                fontWeight: 900,
                textAlign: 'center',
                fontSize: '38px',
                verticalAlign: 'middle',
                whiteSpace: 'pre-line',
                lineHeight: '50px',
                color: '#010142', // should be replaced by theme color, but the backend redirection to ConfirmationScreen should have a partnerName param to make it work with WOOP theme selection
                textTransform: 'none',
                maxHeight: '150px',
                width: '60%',
                position: 'relative',
                margin: '0 auto',
                [theme.breakpoints.down('sm')]: {
                    lineHeight: '120%',
                    width: '60%',
                    height: '100%',
                    fontSize: '26px',
                },
                [theme.breakpoints.between(480, 600)]: {
                    width: '50%',
                },
            },
            confirmationTitle: {
                fontFamily: 'radial-SemiBold',
                fontWeight: 900,
                textAlign: 'center',
                fontSize: '32px',
                marginTop: '30px',
                color: '#000000',
            },
            tutorialImageContainer: {
                display: 'flex',
                marginTop: '15px',
                flexWrap: 'wrap',
                margin: '0 auto',
                justifyContent: 'space-around',
            },
            tutorialImage: {
                width: 'calc(100% / 3 - 5px)',
                maxWidth: '291px',
                [theme.breakpoints.down('sm')]: {
                    flex: '100%',
                    margin: '5px 0',
                },
            },
            tutorialText: {
                fontFamily: 'Plex-Light',
                fontSize: '22px',
                color: '#000000',
                textAlign: 'left',
                marginTop: '30px',
            },
            lastText: {
                fontFamily: 'Radial-SemiBold',
                fontWeight: 900,
                fontSize: '22px',
                color: '#010142', // should be replaced by theme color, but the backend redirection to ConfirmationScreen should have a partnerName param to make it work with WOOP theme selection
                textAlign: 'left',
                marginTop: '30px',
            },
            footer: {
                fontFamily: 'Radial-SemiBold',
                fontWeight: 900,
                fontSize: '24px',
                marginTop: '30px',
                lineHeight: '130%',
                padding: '25px 40px',
                margin: '0 auto',
                backgroundColor: '#DBDEFF',
                textAlign: 'center',
                [theme.breakpoints.down('sm')]: {
                    margin: '30px -20px 0 -20px',
                },
            },
            goToPortal: {
                fontSize: '24px',
                position: 'relative',
                top: '50%',
                transform: 'translateY(-50%)',
                fontFamily: 'Radial-SemiBold',
                fontWeight: 900,
                textAlign: 'center',
                color: '#132AFF', // should be replaced by theme color, but the backend redirection to ConfirmationScreen should have a partnerName param to make it work with WOOP theme selection
            },
        }),
    );
    const classes = useStyles();

    useEffect(() => {
        if (status === 'error') {
            document.title = t('WO-something-wrong');
            toast.error(t('WO-something-wrong'), {
                position: 'top-center',
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                draggable: false,
                progress: 0,
            });
        } else {
            document.title = t('WO-page-title');
        }
    }, [status]);

    return (
        <Box className={classes.ticketFinalContainer}>
            {status === 'success' ? (
                <Box className={classes.root}>
                    <Box className={classes.contentContainer}>
                        <Box className={classes.discountContainer}>
                            <div className={classes.discountAmount}>
                                <CheckCircle className={classes.ticketCheckIcon} />
                                {t('WO-congrats')}
                            </div>
                        </Box>
                    </Box>
                    <Box className={classes.confirmationTitle}>{t('WO-title')}</Box>
                    <Box className={classes.tutorialText}>{t('WO-text-before-tuto')}</Box>
                    <Box className={classes.tutorialImageContainer}>
                        <img className={classes.tutorialImage} src={`${assetUrl}tuto-cartoon-fr_1.png`} />
                        <img className={classes.tutorialImage} src={`${assetUrl}tuto-cartoon-fr_2.png`} />
                        <img className={classes.tutorialImage} src={`${assetUrl}tuto-cartoon-fr_3.png`} />
                    </Box>
                    <Box className={classes.tutorialText}>{t('WO-text-after-tuto')}</Box>
                    <Box className={classes.lastText}>{t('WO-last-text')}</Box>
                    <Box className={classes.footer}>{t('WO-footer')}</Box>
                </Box>
            ) : (
                <Box className={classes.goToPortal}>{t('WO-go-to-portal')}</Box>
            )}
            <ToastContainer
                position="top-center"
                theme="colored"
                autoClose={false}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
            />
        </Box>
    );
};
