// eslint-disable-next-line budapestian/global-constant-pattern
import { WhitelabelOptions, PartnerCustomColors } from './../types';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const config: Record<string, any> = {
    name: 'cefr',
};

export const colors: PartnerCustomColors = {
    partnerColor01: '#DB0029',
    partnerColor02: '#666666',
    partnerColor03: '#666666',
    partnerColor04: '#666666',
    partnerColor05: '#DB0029',
    partnerColor06: '#DB0029',
    partnerColor07: '#DB0029',
    partnerColor08: '#FFFFFF',
    partnerColor09: '#666666',
    partnerColor10: '#DB0029',
};

const whitelabel: WhitelabelOptions = {
    colors: colors,
    config: config,
};

export default whitelabel;
