// eslint-disable-next-line budapestian/global-variable-pattern
import React, { useContext } from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { HeroImage, TextButton, TicketOfferMessage } from '../../shared/components';
import './TutorialScreen.scss';
import WhitelabelContext from '../../shared/styles/whitelabel/WhitelabelContext';
import { SelfOptin } from '../../shared/components/SelfOptin/SelfOptin';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Android: any;

export const TutorialScreen: React.FC<Record<string, never>> = () => {
    const { t } = useTranslation('default');

    const whitelabelConfig = useContext(WhitelabelContext);
    const assetUrl = whitelabelConfig.getAssetUrl(whitelabelConfig.name || 'nexi');
    const lHeroImage = `${assetUrl}postpone-screen-banner.png`;

    const onClose = () => {
        if (typeof Android !== 'undefined') {
            Android.onModuleSkipped();
        } else {
            // eslint-disable-next-line no-console
            console.log('notNowAction called');
        }
    };
    return (
        <Box className="tutorial-screen-container">
            <Box className="tutorial-screen-top-container">
                <HeroImage data-qa="shapes-img" imagePath={lHeroImage} />
            </Box>
            <Box className="tutorial-screen-bottom-container">
                <SelfOptin fromScreen={'tutorial'}>
                    <Box className="tutorial-screen-content-container">
                        <TicketOfferMessage
                            title={t('start-enrolling-customers')}
                            firstParagraph={t('optin-explanation')}
                        />
                    </Box>
                    <Box className={'buttons-bottom'}>
                        <TextButton
                            className="close-btn"
                            data-qa="close-btn"
                            text={t('close-and-start')}
                            eventHandler={onClose}
                        />
                    </Box>
                </SelfOptin>
            </Box>
        </Box>
    );
};
