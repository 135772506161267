// eslint-disable-next-line budapestian/global-variable-pattern
import { NodeEnvironment } from '../types/EnvironmentVariables';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Android: any;

export interface IPosInformation {
    lang: string;
    shopUniqueId: string;
    code: string;
    rank: string;
    appPath: string;
    serial: string;
    manufacturer?: string;
    model?: string;
    storeId?: string;
    businessId?: string;
    deviceId?: string;
    partnerName?: string;
}

class PosInformationService implements IPosInformation {
    lang: string;
    shopUniqueId: string;
    code: string;
    rank: string;
    appPath: string;
    serial: string;
    manufacturer: string | undefined;
    model: string | undefined;
    storeId: string | undefined;
    businessId: string | undefined;
    deviceId: string | undefined;
    partnerName: string | undefined;

    constructor(search: string) {
        let lParamString: string;

        if (typeof Android !== 'undefined') {
            lParamString = Android.getParam();
        } else {
            lParamString = new URLSearchParams(search).get('param') ?? '{}';
        }

        const params = JSON.parse(lParamString);
        this.lang = params.lang ?? 'en_US';

        if (params.shopUniqueId) {
            this.shopUniqueId = params.shopUniqueId;
        } else {
            throw new Error('shopUniqueId not set');
        }
        if (params.code) {
            this.code = params.code;
        } else {
            throw new Error('code not set');
        }
        if (params.rank) {
            this.rank = params.rank;
        } else {
            throw new Error('rank not set');
        }
        if (params.appPath) {
            this.appPath = params.appPath;
        } else {
            throw new Error('appPath not set');
        }
        if (params.partnerName) {
            this.partnerName = params.partnerName;
        }
        this.manufacturer = params.manufacturer;
        this.model = params.model;
        this.serial = params.serial;
        this.storeId = params.storeId;
        this.businessId = params.businessId;
        this.deviceId = params.deviceId;
    }
}

class PosInformationMock implements IPosInformation {
    lang: string;
    shopUniqueId: string;
    code: string;
    rank: string;
    appPath: string;
    serial: string;
    manufacturer: string | undefined;
    model: string | undefined;
    storeId: string | undefined;
    businessId: string | undefined;
    deviceId: string | undefined;
    partnerName: string | undefined;

    constructor() {
        const lMockInfo = {
            lang: 'en_US',
            shopUniqueId: 'mockShopUniqueId',
            code: 'mockCode',
            rank: 'mockRank',
            appPath: 'mockAppPath',
            serial: 'mockSerial',
            partnerName: 'mockPartnerName',
        };

        this.lang = lMockInfo.lang;
        this.shopUniqueId = lMockInfo.shopUniqueId;
        this.code = lMockInfo.code;
        this.rank = lMockInfo.rank;
        this.appPath = lMockInfo.appPath;
        this.serial = lMockInfo.serial;
        this.partnerName = lMockInfo.partnerName;
    }
}

export const posInformation = (search: string): IPosInformation => {
    const nodeEnv: NodeEnvironment = process.env.NODE_ENV;
    if (nodeEnv === 'production' || 'test') {
        return new PosInformationService(search);
    } else {
        return new PosInformationMock();
    }
};
