import { useEffect } from 'react';
import { IPosInformation } from '../services/PosInformation.service';
import { trackingService } from '../services/Tracking.service';

type MixpanelProps = {
    ShopUniqueId: string;
    Locale: string;
    Serial: string;
    Manufacturer: string | undefined;
    PoyntBusinessId: string | undefined;
    PoyntStoreId: string | undefined;
    PoyntDeviceId: string | undefined;
    Partner: string | undefined;
};

/**
 * A custom hook that tracks opening a screen and tracks the time it was displayed.
 * It uses the useEffect to fire events once on mount and once on unmount.
 * @param {string} screen - The name of screen this hook is used on.
 * @param {IPosInformation} posInfo - Information provided from the posInfo service, used for tracking as parameters.
 * @returns {MixpanelProps} Returns the properties from the posInfo to be used in the required format.
 */
export function useTracking(screen: string, posInfo: IPosInformation): MixpanelProps {
    const lMixpanelProps = {
        ShopUniqueId: posInfo.shopUniqueId,
        Locale: posInfo.lang,
        Serial: posInfo.serial,
        Manufacturer: posInfo.manufacturer,
        PoyntBusinessId: posInfo.businessId,
        PoyntStoreId: posInfo.storeId,
        PoyntDeviceId: posInfo.deviceId,
        Partner: posInfo.partnerName,
    };
    useEffect(() => {
        // invoke event for loading screen and start tracking time for the timed event on mount
        trackingService.track(`WelcomeOfferSetup.${screen}`, lMixpanelProps);
    }, []);
    return lMixpanelProps;
}
