import { NodeEnvironment } from '../types/EnvironmentVariables';

type ValidateRequest = {
    terminal: {
        shopUniqueId: string;
        code: string;
        rank: string;
    };
    reward: string;
    unit: 'CASH' | 'PERCENT' | 'GIFT';
    validityDuration: number;
};

type GetShopParameters = {
    shopUniqueId: string;
    code: string;
    rank: string;
};

export type GetShopResponse = {
    welcomeOfferActivated: boolean;
    optinsNumber: number;
};

export type ValidateParameters = {
    request: ValidateRequest;
    appPath: string;
};

export interface IRestAPI {
    validate: (params: ValidateParameters) => Promise<Response>;
    getShopInfo: (params: GetShopParameters) => Promise<Response>;
}

class RestAPI implements IRestAPI {
    validate = async (params: ValidateParameters): Promise<Response> => {
        // we'll have to use only https://terminal.izicap.com/rest/${params.appPath}/saveWelcomeOffer in the future,
        // but for now there is a ERR_CERT_AUTHORITY_INVALID to handle (cf WOOP-79), so the quickest workaround is to hardcode the call to nexi's backend.
        let lWelcomeOfferAPI: string;
        const shopUniqueId = Number(params.request.terminal.shopUniqueId);
        if (shopUniqueId >= 1050000 && shopUniqueId < 1060000) {
            lWelcomeOfferAPI = `https://pp-nexi.izicap.com/rest/poynt/saveWelcomeOffer`;
        } else if (shopUniqueId >= 1070000 && shopUniqueId < 1080000) {
            lWelcomeOfferAPI = `https://pp-mastercard.izicap.com/rest/castles/saveWelcomeOffer`;
        } else if (shopUniqueId >= 6000000 && shopUniqueId < 7000000) {
            lWelcomeOfferAPI = `https://www.izicap.it/rest/poynt/saveWelcomeOffer`;
        } else if (shopUniqueId >= 7000000 && shopUniqueId < 8000000) {
            lWelcomeOfferAPI = `https://mkt.merchantinsights.cashing.pt/rest/castles/saveWelcomeOffer`;
        } else if (shopUniqueId >= 700000 && shopUniqueId < 1000000) {
            lWelcomeOfferAPI = `https://www.ce-boost-fid.fr/rest/paxNps/saveWelcomeOffer`;
        } else if (shopUniqueId >= 1020000 && shopUniqueId < 1030000) {
            lWelcomeOfferAPI = `https://preprod.izicap.com/rest/paxNps/saveWelcomeOffer`;
        } else if (shopUniqueId >= 1120000 && shopUniqueId < 1130000) {
            lWelcomeOfferAPI = `https://pp-ce.izicap.com/rest/paxNps/saveWelcomeOffer`;
        } else {
            lWelcomeOfferAPI = `https://terminal.izicap.com/rest/${params.appPath}/saveWelcomeOffer`; // default value to avoid TS error
        }

        const response = await fetch(lWelcomeOfferAPI, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(params.request),
        });
        if (!response.ok) {
            const error = response.status;
            return Promise.reject(error);
        }
        return response;
    };

    getShopInfo = (params: GetShopParameters): Promise<Response> => {
        let lTargetApi = '';
        // we'll have to use only https://terminal.izicap.com/rest/${params.appPath}/getShop in the future,
        // but for now there is a ERR_CERT_AUTHORITY_INVALID to handle (cf WOOP-79), so the quickest workaround is to hardcode the call to nexi's backend.
        const shopUniqueIdNum = Number(params.shopUniqueId);
        if (shopUniqueIdNum >= 1050000 && shopUniqueIdNum < 1060000) {
            lTargetApi = `https://pp-nexi.izicap.com/rest/pos/getShop`;
        } else if (shopUniqueIdNum >= 1070000 && shopUniqueIdNum < 1080000) {
            lTargetApi = `https://pp-mastercard.izicap.com/rest/castles/getShop`;
        } else if (shopUniqueIdNum >= 6000000 && shopUniqueIdNum < 7000000) {
            lTargetApi = `https://www.izicap.it/rest/poynt/getShop`;
        } else if (shopUniqueIdNum >= 7000000 && shopUniqueIdNum < 8000000) {
            lTargetApi = `https://mkt.merchantinsights.cashing.pt/rest/castles/getShop`;
        } else if (shopUniqueIdNum >= 700000 && shopUniqueIdNum < 1000000) {
            lTargetApi = `https://www.ce-boost-fid.fr/rest/paxNps/getShop`;
        } else if (shopUniqueIdNum >= 1020000 && shopUniqueIdNum < 1030000) {
            lTargetApi = `https://preprod.izicap.com/rest/paxNps/getShop`;
        } else if (shopUniqueIdNum >= 1120000 && shopUniqueIdNum < 1130000) {
            lTargetApi = `https://pp-ce.izicap.com/rest/paxNps/getShop`;
        }

        const { shopUniqueId, code, rank } = params;
        const lData = {
            terminal: { shopUniqueId, code, rank },
        };

        return fetch(lTargetApi, {
            method: 'POST',
            body: JSON.stringify(lData),
            headers: {
                'Content-Type': 'application/json',
            },
        });
    };
}

class MockRestAPI implements IRestAPI {
    validate = async (params: ValidateParameters): Promise<Response> => {
        // eslint-disable-next-line no-console
        console.log('validate', params);
        return Promise.resolve({} as Response);
    };

    getShopInfo = async (params: GetShopParameters): Promise<Response> => {
        // eslint-disable-next-line no-console
        console.log('getShop', params);
        return Promise.resolve({} as Response);
    };
}

export const restAPI = ((): IRestAPI => {
    const nodeEnv: NodeEnvironment = process.env.NODE_ENV;
    if (nodeEnv === 'production') {
        return new RestAPI();
    } else {
        return new MockRestAPI();
    }
})();
