import { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PromotionType } from '../../shared/types/Promotion.type';
import { WelcomeOfferType } from '../../shared/types/OfferTypeScreen.type';

type InputValidType = {
    valid: boolean;
    message: string | null;
};

export type UseInputValidationReturn = {
    discountValue: string;
    setDiscountValue: React.Dispatch<string>;
    inputValid: InputValidType;
};

/**
 * A custom hook that validates the field input. It uses the useReducer to manage the state.
 * It also uses the useState hook to manage the input value.
 * It also uses the useTranslation hook to get the translation strings.
 * @param {PromotionType} promotion - The promotion object.
 * @param {WelcomeOfferType} offer - The offer object.
 * @returns {UseInputValidationReturn} Returns the input validation state and the setter function.
 */
export const useInputValidation = (promotionType: PromotionType, offer: WelcomeOfferType): UseInputValidationReturn => {
    const { t } = useTranslation('default');
    const sanitizeRecommendedDiscount = (discount: string) => {
        if (promotionType !== PromotionType.Gift) {
            if (isNaN(parseFloat(discount))) {
                return discount;
            } else {
                return Math.round(parseFloat(discount)).toString();
            }
        } else {
            return discount;
        }
    };
    const validateInput = (formInput: string) => {
        if (formInput.length < 1) {
            return { valid: false, message: t('required-field-error') };
        } else if (promotionType !== PromotionType.Gift) {
            if (!/^[0-9]+$/.test(formInput)) {
                return {
                    valid: false,
                    message: t('number-input-limit-error', {
                        minValue: offer.minRewardValue,
                        maxValue: offer.maxRewardValue,
                    }),
                };
            } else if (parseInt(formInput) < offer.minRewardValue || parseFloat(formInput) > offer.maxRewardValue) {
                return {
                    valid: false,
                    message: t('number-input-limit-error', {
                        minValue: offer.minRewardValue,
                        maxValue: offer.maxRewardValue,
                    }),
                };
            }
        } else if (formInput.length < 1 || formInput.length > 40) {
            return { valid: false, message: t('character-input-limit-error') };
        }
        return { valid: true, message: null };
    };

    const [inputValid, setInputValid] = useState<{ valid: boolean; message: string | null }>({
        valid: promotionType !== PromotionType.Gift,
        message: null,
    });

    const discountValueReducer = (_state: string, action: string) => {
        const sanitizedInput = sanitizeRecommendedDiscount(action);
        const isValidInput = validateInput(sanitizedInput);
        setInputValid(isValidInput);
        return sanitizedInput;
    };

    const [discountValue, setDiscountValue] = useReducer(
        discountValueReducer,
        sanitizeRecommendedDiscount(offer.recommendedDiscount),
    );

    return { discountValue, setDiscountValue, inputValid };
};
