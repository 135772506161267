import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './toaster.scss';

export const Toaster: React.FC = () => {
    return (
        <ToastContainer
            theme="colored"
            position="top-center"
            limit={1}
            hideProgressBar={true}
            transition={Slide}
            closeButton={false}
            autoClose={20000}
        />
    );
};
