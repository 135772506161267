// eslint-disable-next-line budapestian/global-variable-pattern
import React, { ReactElement, ReactNode, useContext } from 'react';
import { POSInfoContext } from '../../contexts/POSInfoContext';
import { restAPI, trackingService } from '../../services';
import { Box } from '@material-ui/core';
import { TicketOfferMessage } from '../TicketOfferMessage/TicketOfferMessage';
import { RoundedButton } from '../RoundedButton/RoundedButton';
import { useTranslation } from 'react-i18next';
import { GetShopResponse } from '../../services/RestAPI.service';

interface SelfOptinInterface {
    children: ReactNode;
    fromScreen: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Android: any;

export const SelfOptin: React.FC<SelfOptinInterface> = (props: SelfOptinInterface): ReactElement => {
    const { children, fromScreen } = props;
    const urlParams = new URLSearchParams(window.location.search);
    const isNotNow = urlParams.get('notNow') || false;
    const { t } = useTranslation('default');
    const posInfo = useContext(POSInfoContext);
    const { shopUniqueId, code, rank } = posInfo;
    const [isSelfOptin, setSelfOptin] = React.useState(false);
    const [isLoading, setLoading] = React.useState(true);
    const lMixpanelProps = {
        ShopUniqueId: posInfo.shopUniqueId,
        Locale: posInfo.lang,
        Serial: posInfo.serial,
        Manufacturer: posInfo.manufacturer,
        PoyntBusinessId: posInfo.businessId,
        PoyntStoreId: posInfo.storeId,
        PoyntDeviceId: posInfo.deviceId,
        Partner: posInfo.partnerName,
    };

    React.useEffect(() => {
        restAPI
            .getShopInfo({ shopUniqueId, code, rank })
            .then(async (res) => {
                const data = (await res.json()) as GetShopResponse;
                if (
                    (data.welcomeOfferActivated && data.optinsNumber == 0) ||
                    (!data.welcomeOfferActivated && data.optinsNumber == 0 && isNotNow)
                ) {
                    trackingService.track('Onboarding.SelfOptinIntro', lMixpanelProps);
                    setSelfOptin(true);
                }
                setLoading(false);
            })
            .catch((e) => {
                setLoading(false);
            });
    }, []);

    const onClick = () => {
        const appVersion = document.querySelector('meta[name="app-version"]')?.getAttribute('content');
        const lAndroidParams = {
            version: appVersion,
            actions: ['self_optin'],
        };

        trackingService.track('Onboarding.SelfOptinIntro.ValidateBtn', lMixpanelProps);
        // eslint-disable-next-line no-console
        console.log('params for Android module', lAndroidParams);

        if (typeof Android !== 'undefined') {
            if (fromScreen === 'ticket') Android.onModuleFinished(JSON.stringify(lAndroidParams));

            if (fromScreen === 'tutorial') Android.onModuleSkipped(JSON.stringify(lAndroidParams));
        }
    };

    if (isLoading) return <div></div>;

    if (!isSelfOptin) return <>{children}</>;

    if (fromScreen === 'tutorial') {
        return (
            <>
                <Box className="tutorial-screen-content-container">
                    <TicketOfferMessage title={t('self-optin-title')} firstParagraph={t('self-optin-text')} />
                </Box>
                <Box className={'buttons-bottom'}>
                    <RoundedButton data-qa="confirm-btn" text={t('self-optin-btn')} eventHandler={onClick} />
                </Box>
            </>
        );
    }

    if (fromScreen === 'ticket') {
        return (
            <>
                <TicketOfferMessage
                    isSelfOptin={isSelfOptin}
                    title={t('self-optin-title')}
                    firstParagraph={t('self-optin-text')}
                    secondParagraph={t('up-to-you')}
                />
                <RoundedButton data-qa="confirm-btn" text={t('self-optin-btn')} eventHandler={onClick} />
            </>
        );
    }

    return <></>;
};
