// eslint-disable-next-line budapestian/global-variable-pattern
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { POSInfoContext } from '../../shared/contexts/POSInfoContext';
import { useTracking } from '../../shared/hooks/useTracking';
import { trackingService } from '../../shared/services/Tracking.service';
import { RoundedButton, SectionSubtitle, SectionTitle, HeroImage, TextButton } from '../../shared/components';
import WhitelabelContext from '../../shared/styles/whitelabel/WhitelabelContext';
import './ActivationScreen.scss';

export const ActivationScreen: React.FC<Record<string, never>> = () => {
    const { t } = useTranslation('default');
    const history = useHistory();
    const posInfo = useContext(POSInfoContext);
    const whitelabelConfig = useContext(WhitelabelContext);
    const assetUrl = whitelabelConfig.getAssetUrl(whitelabelConfig.name || 'nexi');
    const lHeroImage = `${assetUrl}welcome-screen-banner.png`;

    const lMixpanelProps = useTracking('ConfigureWO', posInfo);
    const confirmAction = () => {
        trackingService.track('WelcomeOfferSetup.ConfigureWO.ConfigureBtn', lMixpanelProps);
        history.push('/offer-type');
    };

    const notNowAction = () => {
        trackingService.track('WelcomeOfferSetup.ConfigureWO.NotNowBtn', lMixpanelProps);
        history.push('/tutorial?notNow=true');
    };
    return (
        <Box className="activation-screen-container">
            <Box className="activation-screen-top-container">
                <HeroImage data-qa="shapes-img" imagePath={lHeroImage} />
            </Box>
            <Box className="activation-screen-bottom-container">
                <Box className="activation-screen-content-container">
                    <SectionTitle className={'repeat-customer'} text={t('repeat-customer')} />
                    <SectionSubtitle className={'welcome-offer-desc'} text={t('welcome-offer-desc')} />
                </Box>
                <Box className={'buttons-bottom'}>
                    <RoundedButton
                        data-qa="confirm-btn"
                        text={t('configure-welcome-offer')}
                        eventHandler={confirmAction}
                    />
                    <TextButton text={t('not-now')} eventHandler={notNowAction} className={'not-now-button'} />
                </Box>
            </Box>
        </Box>
    );
};
