// eslint-disable-next-line budapestian/global-variable-pattern
import { Dict } from 'mixpanel-browser';
import { NodeEnvironment } from '../types/EnvironmentVariables';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let Android: any;
export interface ITracking {
    identify: (uniqueId: string) => void;
    track: (eventName: string, properties?: Dict) => void;
    time_event: (eventName: string) => void;
}

class TrackingServiceMixpanel implements ITracking {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    mixpanel: any;
    constructor(mixpanelToken: string) {
        if (typeof Android !== 'undefined') {
            this.mixpanel = Android;
        } else {
            this.mixpanel = require('mixpanel-browser');
            this.mixpanel.init(mixpanelToken);
        }
    }

    identify(uniqueId: string): void {
        if (typeof Android !== 'undefined') {
        } else {
            this.mixpanel.identify(uniqueId);
        }
    }

    track(eventName: string, properties?: Dict): void {
        // options parameter send_immediately = true - to prevent track events to be pushed as batch,this way it will be saved in proper order
        if (typeof Android !== 'undefined') {
            const stringifiedProps = properties ? JSON.stringify(properties) : undefined;
            this.mixpanel.track(eventName, stringifiedProps);
        } else {
            this.mixpanel.track(eventName, properties);
        }
    }

    time_event(eventName: string): void {
        this.mixpanel.time_event(eventName);
    }
}

class TrackingServiceMock implements ITracking {
    identify(_uniqueId: string): void {
        return;
    }
    track(_eventName: string): void {
        return;
    }
    time_event(_eventName: string): void {
        return;
    }
}
export const trackingService = ((): ITracking => {
    const nodeEnv: NodeEnvironment = process.env.NODE_ENV;
    // use dev token for preprod and staging hack
    // TODO: we need to come up with a better solution for how to handle mixpanel token configuration instead of using window.location
    if (
        window.location.hostname === 'pp-welcome-offer.izicap.com' ||
        window.location.hostname === 'staging-welcome-offer.izicap.com'
    ) {
        const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN ?? '35f2a02b8daa1136914751d3e59e380f';
        return new TrackingServiceMixpanel(mixpanelToken);
    } else if (nodeEnv === 'production') {
        const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN ?? 'd70906426a8b43d9f1e67a0bf05bd1aa';
        return new TrackingServiceMixpanel(mixpanelToken);
    } else if (nodeEnv === 'development') {
        const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN ?? '35f2a02b8daa1136914751d3e59e380f';
        return new TrackingServiceMixpanel(mixpanelToken);
    } else {
        return new TrackingServiceMock();
    }
})();
