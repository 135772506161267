import React, { useContext } from 'react';
import { Box, createStyles, FormControl, makeStyles, Theme, useTheme } from '@material-ui/core';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GoBackButton, ProgressBar, RoundedButton, SectionSubtitle, SectionTitle } from '../../shared/components';
import { POSInfoContext } from '../../shared/contexts/POSInfoContext';
import { PromotionTextInput } from '../../shared/components/PromotionTextInput/PromotionTextInput';
import { useTracking } from '../../shared/hooks/useTracking';
import { restAPI, ValidateParameters } from '../../shared/services/RestAPI.service';
import { trackingService } from '../../shared/services/Tracking.service';
import { WelcomeOfferType } from '../../shared/types/OfferTypeScreen.type';
import { PromotionType } from '../../shared/types/Promotion.type';
import { PromotionTypeIndicator } from './PromotionTypeIndicator/PromotionTypeIndicator';
import { useInputValidation } from './useInputValidation';
import './PromotionScreen.scss';

type Progress = {
    currentStep: number;
    numberOfSteps: number;
};

interface IOfferTypeScreen {
    progress: Progress;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        promotionInputContainer: {
            backgroundColor: theme.palette.colorSystem.main + '1A',
            border: `1px solid ${theme.palette.colorGrey1?.main}`,
            boxShadow: `inset 4px 4px 8px ${theme.palette.colorGrey2.main}`,
        },
        promotionInputWithSuffix: {
            borderBottom: `5px solid ${theme.palette.partnerColor09?.main}`,
            [theme.breakpoints.down('xs')]: {
                borderBottomWidth: '2.5px',
            },
        },
        promotionInputWithoutSuffix: {
            margin: '0 auto',
            borderBottom: `5px solid ${theme.palette.partnerColor09?.main}`,
            [theme.breakpoints.down('xs')]: {
                borderBottomWidth: '2.5px',
            },
            width: '80%',
        },
        promotionSuffix: {
            color: theme.palette.partnerColor01?.main,
            borderBottom: `5px solid ${theme.palette.partnerColor09?.main}`,
            [theme.breakpoints.down('xs')]: {
                borderBottomWidth: '2.5px',
            },
        },
        promotionFormSubtitle: {
            color: theme.palette.partnerColor02?.main,
        },
    }),
);

export const PromotionScreen: React.FC<IOfferTypeScreen> = (props: IOfferTypeScreen) => {
    const { t } = useTranslation('default');
    const location = useLocation();
    const history = useHistory();
    const posInfo = useContext(POSInfoContext);
    const lMixpanelProps = useTracking('ChooseValue', posInfo);
    const classes = useStyles();
    const theme = useTheme();
    const notify = (notification: string) => {
        toast.error(notification, { icon: <CancelRoundedIcon />, toastId: 'toast-error' });
    };

    const promotionType = (location.state as { promotionType: PromotionType }).promotionType;
    const chosenOffer = (location.state as { offer: WelcomeOfferType }).offer;

    const { discountValue, setDiscountValue, inputValid } = useInputValidation(promotionType, chosenOffer);

    const handleGoBack = () => {
        history.goBack();
    };

    const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setDiscountValue(discountValue);
        if (!inputValid.valid) {
            return;
        } else {
            const lRequestParameters: ValidateParameters = {
                request: {
                    terminal: { shopUniqueId: posInfo.shopUniqueId, code: posInfo.code, rank: posInfo.rank },
                    reward: discountValue,
                    unit: promotionType.toUpperCase() as 'CASH' | 'PERCENT' | 'GIFT',
                    validityDuration: chosenOffer.duration,
                },
                appPath: posInfo.appPath,
            };
            trackingService.track('WelcomeOfferSetup.ChooseValue.ValidateBtn', {
                ...lMixpanelProps,
                WoValue: discountValue,
            });
            return restAPI
                .validate(lRequestParameters)
                .then(() => {
                    history.push({
                        pathname: '/ticket',
                        search: `?ticket=${discountValue}`,
                        state: {
                            discount: discountValue,
                            promotionType: promotionType,
                            validityDuration: chosenOffer.duration,
                        },
                    });
                })
                .catch(() => {
                    notify(t('error-retry'));
                });
        }
    };

    const lPromotionTypeKey = {
        [PromotionType.Percent]: 'percent-discount',
        [PromotionType.Cash]: 'euro-discount',
        [PromotionType.Gift]: 'a-gift',
    };

    const handleMaxLength = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (e.target.value.length >= e.target.maxLength) {
            e.target.value = e.target.value.slice(0, e.target.maxLength - 1);
            e.preventDefault();
        }
    };

    return (
        <Box className="offer-type-container" bgcolor={theme.palette.colorGrey1?.main}>
            <GoBackButton eventHandler={handleGoBack} />
            <Box className="offer-type-inner-container" bgcolor={theme.palette.colorWhite?.main}>
                <Box className="offer-type-wrapper">
                    <SectionTitle className="promotion-title" text={t('configure-welcome-offer-title')} />
                    <ProgressBar
                        currentStep={props.progress.currentStep}
                        numberOfSteps={props.progress.numberOfSteps}
                    />
                    <SectionSubtitle text={t('define-discount-recomendation')} className="promotion-subtitle" />

                    <PromotionTypeIndicator title={t(lPromotionTypeKey[promotionType])} type={promotionType} />
                    <form onSubmit={submitForm} autoComplete="off">
                        <FormControl className="promotion-input-form-control">
                            <div className={`${classes.promotionInputContainer} promotion-input-container`}>
                                {promotionType !== PromotionType.Gift && (
                                    <>
                                        <PromotionTextInput
                                            inputMode="numeric"
                                            value={discountValue}
                                            className={`${classes.promotionInputWithSuffix} input-with-suffix`}
                                            min={chosenOffer.minRewardValue}
                                            max={chosenOffer.maxRewardValue}
                                            maxLength={promotionType === PromotionType.Percent ? 2 : 3}
                                            error={inputValid.message}
                                            promotionType={promotionType}
                                            setDiscountValue={setDiscountValue}
                                        />
                                        <div
                                            className={`${classes.promotionSuffix} promotion-suffix MuiInputBase-input`}
                                        >
                                            {promotionType === PromotionType.Percent ? '%' : '€'}
                                        </div>
                                    </>
                                )}

                                {promotionType === PromotionType.Gift && (
                                    <PromotionTextInput
                                        min={1}
                                        max={40}
                                        value={discountValue}
                                        className={`${classes.promotionInputWithoutSuffix} input-without-suffix`}
                                        error={inputValid.message}
                                        fullWidth={true}
                                        promotionType={promotionType}
                                        setDiscountValue={setDiscountValue}
                                        handleMaxLength={handleMaxLength}
                                    />
                                )}
                            </div>

                            <SectionSubtitle
                                text={t('discount-offer-duration-merchant', {
                                    offerValidityInDays: chosenOffer.duration,
                                })}
                                className={`promotion-form-subtitle ${classes.promotionFormSubtitle} `}
                            />
                            <Box className={'validate-action-container'}>
                                <RoundedButton text={t('validate-offer')} type="submit" />
                            </Box>
                        </FormControl>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};
