import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface IClientLogo {
    imagePath?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '136px',
            marginBottom: '20px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10px',
            },
        },
    }),
);

const DEFAULT_LOGO = '/assets/images/nexi/welcome-offer-banner.png';

export const ClientLogo: React.FC<IClientLogo> = (props: IClientLogo) => {
    const classes = useStyles();

    return (
        <img data-qa="client-logo" className={classes.root} src={props.imagePath ? props.imagePath : DEFAULT_LOGO} />
    );
};
