import React from 'react';

interface IHeroImage {
    imagePath?: string;
}
const DEFAULT_IMAGE = '/assets/images/nexi/welcome-offer-banner.png';

export const HeroImage: React.FC<IHeroImage> = (props: IHeroImage) => {
    return (
        <img
            data-qa="banner-image"
            style={{ maxWidth: '100%', width: '100%', height: 'auto' }}
            src={props.imagePath ? props.imagePath : DEFAULT_IMAGE}
        />
    );
};
