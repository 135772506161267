import React, { useContext } from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import WhitelabelContext from '../../styles/whitelabel/WhitelabelContext';

interface ITicketOfferMessage {
    title: string;
    firstParagraph: string;
    secondParagraph?: string;
    imagePath?: string;
    isSelfOptin?: boolean;
}

export const TicketOfferMessage: React.FC<ITicketOfferMessage> = (props: ITicketOfferMessage) => {
    const whitelabelConfig = useContext(WhitelabelContext);
    const assetUrl = whitelabelConfig.getAssetUrl();
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                backgroundImage: `url('${assetUrl}message-bubble.png')`,
                height: '430px',
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                borderRadius: '15px',
                [theme.breakpoints.down('sm')]: {
                    height: props.isSelfOptin ? '380px' : '300px',
                },
                [theme.breakpoints.down('lg')]: {
                    marginTop: '24px',
                },
                [theme.breakpoints.only('sm')]: {
                    backgroundPosition: 'top center',
                    height: '340px',
                },
                [theme.breakpoints.up('md')]: {
                    height: props.isSelfOptin ? '410px' : '360px',
                },
                [theme.breakpoints.down('xs')]: {
                    backgroundPosition: 'top center',
                    marginTop: 0,
                    height: props.isSelfOptin ? '250px' : '210px',
                },
            },
            contentContainer: {
                padding: '50px 40px',
                maxWidth: '550px',
                width: 'calc(100% - 80px)',
                margin: '0 auto',
                [theme.breakpoints.up('md')]: {
                    paddingTop: '32px',
                },
                [theme.breakpoints.down('md')]: {
                    paddingTop: '12px',
                },
                [theme.breakpoints.down('xs')]: {
                    padding: props.isSelfOptin ? '12px 10px 50px 10px' : '12px 20px 50px 20px',
                    width: props.isSelfOptin ? 'calc(100% - 20px)' : 'calc(100% - 40px)',
                },
            },
            messageTitle: {
                fontSize: '38px',
                color: theme.palette.partnerColor01?.main,
                fontFamily: 'Radial-SemiBold,sans-serif',
                fontWeight: 800,
                lineHeight: '41px',
                textTransform: 'none',

                [theme.breakpoints.down('lg')]: {
                    fontSize: '24px',
                    marginTop: '12px',
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: '22px',
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: '17px',
                    lineHeight: '20px',
                    marginTop: 0,
                },
            },
            spacer: {
                marginBottom: '16px',
                [theme.breakpoints.down('xs')]: {
                    display: 'none',
                },
            },
            messageContent: {
                fontSize: '26px',
                color: theme.palette.partnerColor02?.main,
                lineHeight: '43px',
                marginTop: '22px',
                [theme.breakpoints.down('lg')]: {
                    fontSize: '21px',
                    lineHeight: '25px',
                    marginTop: '10px',
                },
                [theme.breakpoints.down('sm')]: {
                    fontSize: '18px',
                    lineHeight: '22px',
                    marginTop: '16px',
                },
                [theme.breakpoints.down('xs')]: {
                    fontSize: '13px',
                    lineHeight: '19px',
                    marginTop: '8px',
                },
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.contentContainer}>
                <Typography data-qa="ticket-msg-title" variant="h2" className={classes.messageTitle}>
                    {props.title}
                </Typography>
                <Typography data-qa="optin-explanation" variant="subtitle1" className={classes.messageContent}>
                    {props.firstParagraph}
                    <div className={classes.spacer}></div>
                    {props.secondParagraph}
                </Typography>
            </Box>
        </Box>
    );
};
