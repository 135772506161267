import i18next, { i18n as i18nInstance } from 'i18next';
import * as en_US from '../translations/en_US.json';
import * as es_ES from '../translations/es_ES.json';
import * as fr_FR from '../translations/fr_FR.json';
import * as it_IT from '../translations/it_IT.json';
import * as pt_PT from '../translations/pt_PT.json';

import { initReactI18next } from 'react-i18next';

export const createI18n = (language: string, fallbackLanguage?: string): i18nInstance => {
    const i18n = i18next.createInstance().use(initReactI18next);

    i18n.init({
        lng: language,
        fallbackLng: fallbackLanguage ?? 'fr_FR',
        resources: {
            en_US: en_US,
            es_ES: es_ES,
            fr_FR: fr_FR,
            it_IT: it_IT,
            pt_PT: pt_PT,
        },
    });

    return i18n;
};
