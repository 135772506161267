import React from 'react';

import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { PromotionType } from '../../../shared/types/Promotion.type';
import { ReactComponent as CashIcon } from './../../../assets/common/icons/cash-icon.svg';
import { ReactComponent as PercentIcon } from './../../../assets/common/icons/percent-icon.svg';
import { ReactComponent as GiftIcon } from './../../../assets/common/icons/gift-icon.svg';

interface IPromotionTypeIndicator {
    type: PromotionType;
    title?: string;
}

export const PromotionTypeIndicator: React.FC<IPromotionTypeIndicator> = (props: IPromotionTypeIndicator) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                position: 'relative',
            },

            offerIconContainer: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                marginRight: '12px',
            },
            offerIcon: {
                backgroundColor: theme.palette.colorWhite?.main,
                height: '40px',
                width: '40px',
                fill: `${
                    (props.type === PromotionType.Percent && theme.palette.partnerColor01?.main) ||
                    (props.type === PromotionType.Cash && theme.palette.partnerColor01?.main) ||
                    (props.type === PromotionType.Gift && theme.palette.partnerColor01?.main)
                }`,
                [theme.breakpoints.down('xs')]: {
                    height: '25px',
                    width: '25px',
                },
            },
            offerTitleContainer: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
                width: '65%',
                padding: '50px 0px',
                color: theme.palette.partnerColor02?.main,
                [theme.breakpoints.down('lg')]: {
                    padding: '12px 0',
                },
            },
            offerTitle: {
                fontWeight: 800,
                fontSize: '32px',
                lineHeight: '46px',
                fontFamily: 'Radial-SemiBold',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '18px',
                    lineHeight: '22px',
                },
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Box className={classes.offerIconContainer}>
                {(props.type === PromotionType.Cash && <CashIcon className={classes.offerIcon} />) ||
                    (props.type === PromotionType.Percent && <PercentIcon className={classes.offerIcon} />) ||
                    (props.type === PromotionType.Gift && <GiftIcon className={classes.offerIcon} />)}
            </Box>
            <Box className={classes.offerTitleContainer}>
                <Typography data-qa="promotion-title" variant="h3" className={classes.offerTitle}>
                    {props.title}
                </Typography>
            </Box>
        </Box>
    );
};
