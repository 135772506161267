// eslint-disable-next-line budapestian/global-variable-pattern
// eslint-disable-next-line budapestian/global-constant-pattern
import { createTheme, Theme, ThemeOptions } from '@mui/material';
import { PartnerCustomColors } from './types';
import chroma from 'chroma-js';
import '../fonts.scss';

export const systemColors = {
    applicationWhite: '#FFFFFF',
    applicationBlack: '#000000',
    applicationGrey1: '#E6E6EC',
    applicationGrey2: '#E9E9EA',
    applicationSystem1: '#9ABFFF',
    applicationSuccess: '#7CDCB3',
    applicationWarning: '#FFC266',
    applicationError: '#FF8466',
    applicationInfo: '#D5E9EB',
};

export function createPartnerTheme(colors: PartnerCustomColors): Theme {
    return createTheme({
        breakpoints: {
            values: {
                xs: 361,
                sm: 581,
                md: 601,
                lg: 1201,
                xl: 1537,
            },
        },
        palette: {
            partnerColor01: { main: colors.partnerColor01 },
            partnerColor02: { main: colors.partnerColor02 },
            partnerColor03: { main: colors.partnerColor03 },
            partnerColor04: { main: colors.partnerColor04 },
            partnerColor05: { main: colors.partnerColor05 },
            partnerColor06: { main: colors.partnerColor06 },
            partnerColor07: { main: colors.partnerColor07 },
            partnerColor08: { main: colors.partnerColor08 },
            partnerColor09: { main: colors.partnerColor09 },
            partnerColor10: { main: colors.partnerColor10 },
            success: { main: chroma(systemColors.applicationSuccess).alpha(0.9).css() },
            warning: { main: chroma(systemColors.applicationWarning).alpha(0.9).css() },
            error: { main: chroma(systemColors.applicationError).alpha(0.9).css() },
            info: { main: chroma(systemColors.applicationInfo).alpha(0.9).css() },
            colorSuccess: { main: chroma(systemColors.applicationSuccess).alpha(0.9).css() },
            colorWarning: { main: chroma(systemColors.applicationWarning).alpha(0.9).css() },
            colorError: { main: chroma(systemColors.applicationError).alpha(0.9).css() },
            colorInfo: { main: chroma(systemColors.applicationInfo).alpha(0.9).css() },
            colorWhite: { main: systemColors.applicationWhite },
            colorBlack: { main: systemColors.applicationBlack },
            colorSystem: { main: '#9ABFFF' },
            colorGrey1: { main: '#E6E6EC' },
            colorGrey2: { main: '#E9E9EA' },
        },
        typography: {
            fontFamily: ['Radial-SemiBold', 'sans-serif'].join(','),
            // Welcome screen titles
            h1: {
                fontSize: '32px',
                fontWeight: 800,
                textTransform: 'none',
                color: colors.partnerColor02,
            },
            h2: {
                fontSize: '26px',
                fontWeight: 800,
                lineHeight: '41px',
                textTransform: 'none',
                color: colors.partnerColor02,
            },
            // Application section titles
            h3: {
                fontSize: '22px',
                fontFamily: 'Plex-Light',
                fontWeight: 700,
                lineHeight: '38px',
                textTransform: 'none',
                color: colors.partnerColor01,
            },
            h4: {
                fontSize: '18px',
                fontWeight: 600,
                lineHeight: '36px',
                textTransform: 'none',
                color: colors.partnerColor01,
            },
            h5: {
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '36px',
                textTransform: 'none',
            },
            h6: {
                fontSize: '14px',
                fontWeight: 600,
                lineHeight: '36px',
                textTransform: 'none',
            },
            subtitle1: {
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '30px',
                textTransform: 'none',
                fontFamily: 'Plex-Light',
            },
            subtitle2: {
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '21px',
                textTransform: 'none',
            },
            button: {
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '30px',
                fontFamily: 'Radial-SemiBold',
                textTransform: 'none',
                backgroundColor: colors.partnerColor01,
                color: systemColors.applicationWhite,
            },
        },

        components: {
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'shadow' },
                        style: {
                            backgroundColor: systemColors.applicationWhite,
                            color: colors.partnerColor01,
                            '&:hover': {
                                backgroundColor: chroma(systemColors.applicationWhite).darken(0.2).css(),
                            },
                        },
                    },
                ],
                styleOverrides: {
                    root: {
                        backgroundColor: colors.partnerColor01,
                        color: systemColors.applicationWhite,
                        '&:hover': {
                            backgroundColor: chroma(colors.partnerColor01).darken(1).css(),
                        },
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: colors.partnerColor01,
                    },
                },
            },
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        color: colors.partnerColor01,
                    },
                    switchBase: {
                        color: colors.partnerColor01,
                        '&.Mui-checked + .MuiSwitch-track': {
                            backgroundColor: colors.partnerColor01,
                        },
                    },
                },
            },
            MuiBadge: {
                styleOverrides: {
                    badge: {
                        backgroundColor: colors.partnerColor01,
                    },
                },
            },
            MuiIcon: {
                styleOverrides: {
                    root: {
                        width: '1.15em',
                        height: '1.15em',
                    },
                },
            },
            MuiRadio: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: colors.partnerColor01,
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: colors.partnerColor01,
                        },
                    },
                },
            },
            MuiSlider: {
                styleOverrides: {
                    root: {
                        color: colors.partnerColor01,
                    },
                },
            },
            MuiCircularProgress: {
                styleOverrides: {
                    root: {
                        color: colors.partnerColor01,
                    },
                },
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: chroma(colors.partnerColor01).alpha(0.08).css(),
                        },
                        '&:hover': {
                            backgroundColor: chroma(colors.partnerColor01).alpha(0.12).css(),
                        },
                        '&.Mui-selected:hover': {
                            backgroundColor: chroma(colors.partnerColor01).alpha(0.12).css(),
                        },
                    },
                },
            },

            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        backgroundColor: colors.partnerColor01,
                    },
                },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        backgroundColor: systemColors.applicationWhite,
                        '&.Mui-selected': {
                            color: colors.partnerColor01,
                        },
                    },
                },
            },
        },
    } as ThemeOptions);
}
const assetsUrl = 'https://woop-static.s3.eu-west-3.amazonaws.com/assets';
const defaultConfig = {
    // Here will be the props common to ALL whitelabels (if not overriden by them)
    getAssetUrl: (partnerName?: string): string => {
        //if partnerName is empty apply common path
        return `${assetsUrl}/${partnerName ? partnerName : 'common'}/`;
    },
};

export default defaultConfig;
